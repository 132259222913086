<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="login">
		<img class="login-logo" src="@/assets/images/mobile/common/logo.png" alt="">
		<img class="login-name" src="@/assets/images/mobile/login/name.png" alt="">
		<div class="login-ipt">
			<img src="@/assets/images/mobile/login/icon1.png" alt="">
			<input v-model="params.account" type="text" :placeholder="placeholder1" :adjust-position="true">
		</div>
		<div class="login-ipt">
			<img v-if="identityMb == 'S'" src="@/assets/images/mobile/login/icon2.png" alt="">
			<img v-else src="@/assets/images/mobile/login/icon3.png" alt="">
			<input v-model="iptValue2" type="text" :placeholder="placeholder2" :adjust-position="true">
		</div>
		<div @click="submit" class="login-btn" :class="{'login-btn-active': canSubmit}">
			立即登录
		</div>
	</div>
</template>
<script>
	import storage from 'store'
	import * as dd from 'dingtalk-jsapi';
	import {
		mapActions
	} from 'vuex'
	export default {
		data() {
			return {
				identityMb: "",
				placeholder1: "请输入账号",
				placeholder2: "请输入密码",
				iptValue1: "",
				iptValue2: "",
				code:"7777777",
				params:{
					plat:1,
					code:"7777777",
					account:'',
				}
			}
		},
		computed: {
			canSubmit() {
				if (this.params.account) {
					return true
				} else {
					return false
				}
			}
		},
		mounted() {
			// this.identityMb = this.$route.query.identityMb
			// if (this.identityMb == 'S') {
			// 	this.placeholder1 = "请输入学号"
			// 	this.placeholder2 = "请输入姓名"
			// } else {
			// 	this.placeholder1 = "请输入账号"
			// 	this.placeholder2 = "请输入密码"
			// }
		},
		methods: {
			...mapActions(['mbLogin', 'mbUserInfo']),
			submit() {
				if (!this.params.account) {
					this.$toast({
						message: this.placeholder1,
					});
					return
				}
				// if (!this.iptValue2) {
				// 	this.$toast({
				// 		message: this.placeholder2,
				// 	});
				// 	return
				// }
				let params = JSON.parse(JSON.stringify(this.params))
				this.login(params)
				// let params = {}
				// if (this.identityMb == 'S') {
				// 	var that = this
				// 	var that = this
				// 	this.$dd.runtime.permission.requestAuthCode({
				// 	corpId: "ding3e4ffdeee81e84eaee0f45d8e4f7c288", // 企业id
				// 	onSuccess: (info)=> {
				// 		console.log(info.code,'info')
				// 		that.code = info.code// 通过该免登授权码可以获取用户身份
				// 		params = {
				// 			account: that.iptValue1,
				// 			name: that.iptValue2,
				// 			userType: "STUDENT",
				// 			code:that.code
				// 		}
				// 		that.login(params)
				// 	},
				// 	onFail : (err)=> {
				// 		console.log(err,'err')
				// 	}})
					
				// } else {
				// 	params = {
				// 		account: this.iptValue1,
				// 		password: this.iptValue2,
				// 		userType: "SCHOOL_TEACH"
				// 	}
				// 	this.login(params)
				// }
				// console.log("mb端登录信息", params)
				
			},
			//denglu
			login(params){
				this.mbLogin(params).then(async (res) => {
					if (res.code == 0) {
						await this.mbUserInfo()
						let userInfo = storage.get("accessInfo")
						let typeMap = {
							"STUDENT": "学生",
							"SCHOOL_MANAGER": "干部",
							"COLLEGE_MANAGER": "学院老师",
							"SCHOOL_TEACH": "学校老师",
						}
						storage.set("identityMb", typeMap[userInfo.userType])
						if (userInfo.userType == 'STUDENT') {
							this.$router.push({
								path: `/mb/activity`
							})
						} else if (userInfo.userType == 'SCHOOL_TEACH') {
							this.$router.push({
								path: `/mb/manhour`
							})
						} else {
							this.$toast("不是学校老师账号")
						}
					}
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.login {
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: #FFFFFF;

		.login-logo {
			width: 1.36rem;
			height: 1.36rem;
			margin: 1.12rem auto 0.28rem auto;
		}

		.login-name {
			width: 1.6rem;
			height: 0.54rem;
			margin: 0 auto 0.96rem auto;
		}

		.login-ipt {
			width: 6.22rem;
			height: 0.8rem;
			background: #F8FAFA;
			border-radius: 0.08rem;
			display: flex;
			align-items: center;
			margin: 0 auto 0.32rem auto;
			padding: 0rem 0.32rem;
			box-sizing: border-box;

			img {
				width: 0.32rem;
				height: 0.32rem;
				margin: 0 0.16rem 0 0;
			}

			input {
				flex: 1;
				height: 0.6rem;
				line-height: 0.6rem;
				padding: 0 0;
				margin: 0 0;
				border: 0;
				outline: none;
				background-color: #F8FAFA;
			}
		}

		.login-btn {
			width: 6.22rem;
			height: 0.8rem;
			background: rgba(67, 146, 250, 0.4);
			border-radius: 0.08rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.32rem auto 0rem auto;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
		}

		.login-btn-active {
			background: #4392FA;
		}
	}
</style>
